import React, { useRef } from 'react';
import { useMachine } from '@xstate/react';
import { assign, Machine } from 'xstate';
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import awsconfig from '../aws-exports'
import * as queries from '../graphql/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import ReactModal from 'react-modal'
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";

import './contact-form.css'

Amplify.configure(awsconfig)

const formMachine = Machine({
  initial: 'filling',
  states: {
    filling: {
      on: {
        SUBMIT: {
          target: 'submitting',
          actions: assign({
            data: (c, e) => e.data,
          }),
        }
      }
    },
    submitting: {
      entry: 'refreshCAPTCHA',
      initial: 'captcha',
      states: {
        captcha: {
          on: {
            CAPTCHA: {
              target: 'sending',
              actions: assign({
                captcha: (c, e) => e.captcha,
              }),
            },
          }
        },
        sending: {
          invoke: {
            src: (c, _) => {
              const params = { ...c.data, captcha: c.captcha }
              return API.graphql(graphqlOperation(queries.sendMessage, params))
            },
            onDone: 'done',
            onError: '#failed',
          }
        },
        done: {
          type: 'final',
        },
      },
      onDone: 'sent',
    },
    sent: {
      exit: 'resetForm',
      on: {
        CLOSE: 'filling'
      }
    },
    failed: {
      id: 'failed',
      on: {
        CLOSE: 'filling'
      }
    },
  }
})

const ContactForm = () => {
  const formRef = useRef()
  const recaptchaRef = useRef()
  const resetForm = _ => formRef.current.reset()
  const refreshCAPTCHA = _ => {
    recaptchaRef.current.reset()
    recaptchaRef.current.execute()
  }
  const [current, send] = useMachine(formMachine, {
    actions: { resetForm: resetForm, refreshCAPTCHA: refreshCAPTCHA },
  })
  const onSubmit = data => send('SUBMIT', { data: data })
  const onChange = value => send('CAPTCHA', { captcha: value })
  const onClose = _ => send('CLOSE')
  const { register, handleSubmit, errors } = useForm()

  return (
    <form
      className="w-full max-w-2xl"
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div className="flex flex-wrap -mx-3">
        <div className="w-full mb-6 lg:w-1/2 px-3">
          <label className="block font-bold text-gray-700" htmlFor="name">
            Your Name:
          </label>
          <input
            className="block bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            type="text" name="name" ref={register({ required: 'Required' })}/>
          { errors.name && <p className="text-red-500 text-xs italic">{errors.name.message}</p> }
        </div>
        <div className="w-full mb-6 lg:w-1/2 px-3">
          <label className="block font-bold text-gray-700" htmlFor="email">
            Your Email:
          </label>
          <input
            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            type="email" name="email" ref={register({ required: 'Required' })}/>
          { errors.email && <p className="text-red-500 text-xs italic">{errors.email.message}</p> }
        </div>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mb-6">
          <label className="block font-bold text-gray-700" htmlFor="message">
            Message:
          </label>
          <textarea
            className="block bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full h-56 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
            name="message" ref={register({ required: 'Required' })}/>
          { errors.message && <p className="text-red-500 text-xs italic">{errors.message.message}</p> }
        </div>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mb-6 flex">
          <button
            type="submit"
            className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded m-auto"
            disabled={!current.matches('filling')}>
            {current.matches('submitting') ? "Sending..." : "Send"}
          </button>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        onChange={onChange}
        sitekey="6Lc-n8wUAAAAAJOLARVA5txdGZ2seM44GweqRj16"
        size="invisible"
        className="w-full text-center text-gray-500 text-micro italic leading-none tracking-tight"
      >
        This site is protected by reCAPTCHA and the Google
        {' '}<a className="text-blue-300 hover:text-blue-500" href="https://policies.google.com/privacy">Privacy Policy</a> and
        {' '}<a className="text-blue-300 hover:text-blue-500" href="https://policies.google.com/terms">Terms of Service</a> apply.
      </ReCAPTCHA>
      <ReactModal
        isOpen={current.matches('submitting')}
        overlayClassName="bg-smoke w-full h-full fixed block top-0 left-0 z-50 flex"
        className="m-auto"
        shouldFocusAfterRender={false}
      >
        <FontAwesomeIcon icon={faCircleNotch} spin className="m-auto text-blue-500 text-6xl" />
      </ReactModal>
      <ReactModal
        isOpen={current.matches('sent')}
        overlayClassName="bg-smoke w-full h-full fixed block top-0 left-0 z-50 flex"
        className="bg-white rounded shadow p-8 m-auto w-10/12 lg:w-1/3 max-h-56 text-center flex flex-col"
        shouldFocusAfterRender={false}
        onRequestClose={onClose}
      >
        <span>
          <FontAwesomeIcon icon={faCheckCircle} className="text-blue-500 text-6xl" />
        </span>
        <span className="m-auto text-gray-700 my-5">
          Thank you for contacting us!<br/>We'll get back to you soon.
        </span>
        <button
          onClick={onClose}
          className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mx-auto"
        >
          Close
        </button>
      </ReactModal>
      <ReactModal
        isOpen={current.matches('failed')}
        overlayClassName="bg-smoke w-full h-full fixed block top-0 left-0 z-50 flex"
        className="bg-white rounded shadow p-8 m-auto w-10/12 lg:w-1/3 max-h-56 text-center flex flex-col"
        shouldFocusAfterRender={false}
        onRequestClose={onClose}
      >
        <span>
          <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500 text-6xl" />
        </span>
        <span className="m-auto text-gray-700 my-5">
          Sorry, something went wrong.<br/>Please retry again.
        </span>
        <button
          onClick={onClose}
          className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded mx-auto"
        >
          Close
        </button>
      </ReactModal>
    </form>
  )
}

export default ContactForm
