/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sendMessage = `query SendMessage(
  $name: String!
  $email: AWSEmail!
  $message: String!
  $captcha: String!
) {
  sendMessage(name: $name, email: $email, message: $message, captcha: $captcha)
}
`;
