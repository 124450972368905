import React from "react"
import { graphql, StaticQuery } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Services from '../components/services';
import ContactForm from "../components/contact-form"
import { withPreview } from 'gatsby-source-prismic-graphql';

export const query = graphql`query{
    site {
        siteMetadata {
            title
            description
            author
        }
    }
    prismic {
        allHomepages(uid:"homepage") {
            edges {
                node {
                    services {
                        name
                        description
                        image
                        imageSharp {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    hero_message
                    hero_image
                    hero_imageSharp {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 4160) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                            }
                        }
                    }
                }
            }
        }
    }
}`

const renderIndexPage = data => {
  const siteMetadata = data.site.siteMetadata
  const homepage = data.prismic.allHomepages.edges[0].node
  return (
    <Layout siteMetadata={siteMetadata}>
      <SEO title="Home" siteMetadata={siteMetadata}/>
      <section>
        <Hero
          message={homepage.hero_message}
          image={homepage.hero_image}
          imageSharp={homepage.hero_imageSharp}
        />
      </section>
      <section id="services">
        <h1 className="w-full pt-16 text-3xl lg:text-5xl font-bold leading-tight text-center">
          Services
        </h1>
        <div className="w-full mb-8">
          <div className="h-1 mx-auto bg-blue-900 w-32 opacity-25 my-0 py-0 rounded-t"/>
        </div>
        <div className="flex justify-center px-3">
          <Services items={homepage.services}/>
        </div>
      </section>
      <section id="contact-us">
        <h1 className="w-full pt-16 text-3xl lg:text-5xl font-bold leading-tight text-center">
          Contact us
        </h1>
        <div className="w-full mb-8">
          <div className="h-1 mx-auto bg-blue-900 w-32 opacity-25 my-0 py-0 rounded-t"/>
        </div>
        <div className="flex justify-center px-3">
          <ContactForm/>
        </div>
      </section>
    </Layout>
  )
}

const IndexPage = () => (
  <StaticQuery query={query} render={withPreview(renderIndexPage, query)}/>
)

export default IndexPage

IndexPage.query = query