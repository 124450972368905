// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://xu7fpnq3mvhoxn3edrje2gprui.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "us-west-2:46d02a65-1ff6-4f02-a737-7283e4db8290",
    "aws_cognito_region": "us-west-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "d51491c057cd4333832db58e1d004933",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
