import React from "react"
import PropTypes from "prop-types"
import { RichText } from 'prismic-reactjs';
import GatsbyBackgroundImage from 'gatsby-background-image';

import './hero.css'

const BackgroundImage = ({ image, imageSharp, children, ...rest }) => {
  if (imageSharp) {
    return (
      <GatsbyBackgroundImage fluid={imageSharp.childImageSharp.fluid} {...rest}>
        {children}
      </GatsbyBackgroundImage>
    )
  } else {
    return (
      <div style={{ backgroundImage: `url(${image.url})` }} {...rest}>
        {children}
      </div>
    )
  }
}

const Hero = ({ message, image, imageSharp }) => {
  return (
    <BackgroundImage
      image={image}
      imageSharp={imageSharp}
      className="w-full min-h-48 bg-cover lg:h-screen relative"
    >
      <div className="w-full h-full absolute" style={{backgroundColor: 'black', opacity: 0.5}}/>
      <div className="hero-message w-full lg:w-5/6 min-h-48 lg:h-full relative text-white font-extrabold py-4 px-4 lg:py-24 lg:px-24">
        <RichText render={message}/>
      </div>
    </BackgroundImage>
  )
}

Hero.propTypes = {
  message: PropTypes.arrayOf(PropTypes.object),
  image: PropTypes.object,
  imageSharp: PropTypes.object,
}

export default Hero
