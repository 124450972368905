import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react';
import ReactModal from 'react-modal'

import Logo from '../images/logo.svg'

const Header = ({ siteTitle }) => {
  const [menuVisible, setMenuVisible] = useState(false)
  const toggleMenu = () => setMenuVisible(!menuVisible)
  const closeMenu = () => setMenuVisible(false)

  return (
    <div>
      <nav className="fixed w-full h-16 flex items-center justify-between flex-wrap bg-blue-500 shadow p-3 z-10">
        <div className="flex items-center flex-shrink-0 text-white h-full mr-12">
          <Logo className="h-full w-auto py-1 px-3 rounded-full bg-white"/>
        </div>
        <div className="block lg:hidden">
          <button onClick={toggleMenu}
            className="flex items-center px-3 py-2 border rounded text-blue-200 border-blue-400 hover:text-white hover:border-white">
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
          </button>
        </div>
        <div className="w-full block flex-grow hidden bg-blue-500 lg:visible lg:flex lg:items-center lg:w-auto">
          <div className="text-base flex-grow">
            <Link to="/#services" className="block lg:inline-block text-blue-200 hover:text-white mr-10">
              Services
            </Link>
          </div>
          <div>
            <Link to="/#contact-us" className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white mt-4 lg:mt-0 lg:flex-shrink">
              Contact us
            </Link>
          </div>
        </div>
      </nav>
      <div className="mt-16" />
      <ReactModal
        isOpen={menuVisible}
        onRequestClose={closeMenu}
        shouldFocusAfterRender={false}
        className="p-3 mt-16 bg-blue-500"
      >
        <div className="text-base flex-grow">
          <Link
            to="/#services"
            className="block lg:inline-block text-blue-200 hover:text-white mr-10"
            onClick={closeMenu}
          >
            Services
          </Link>
        </div>
        <div>
          <Link
            to="/#contact-us"
            className="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-blue-500 hover:bg-white mt-4 lg:mt-0 lg:flex-shrink"
            onClick={closeMenu}
          >
            Contact us
          </Link>
        </div>
      </ReactModal>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
