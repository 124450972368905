import React from "react"
import { RichText } from 'prismic-reactjs';
import GatsbyImage from "gatsby-image"

import './services.css'

const Image = ({ image, imageSharp, ...rest }) =>
  imageSharp
    ? (<GatsbyImage fluid={imageSharp.childImageSharp.fluid} {...rest}/>)
    : (<img src={image.url} {...rest}/>)

const Services = ({ items }) => {
  return (
    <div className="container max-w-5xl">
      {
        items.map((service, index) => {
          const flex_row = index % 2 === 0 ? 'flex-row' : 'flex-row-reverse'
          return (
            <div key={index} className={`flex flex-wrap flex-col sm:${flex_row} mb-6`}>
              <div className="w-full p-3 sm:w-1/2 sm:p-6">
                <h3 className="text-xl lg:text-2xl text-gray-800 font-bold leading-none mb-3">
                  {RichText.asText(service.name)}
                </h3>
                <div className="service-description text-gray-600">
                  <RichText render={service.description}/>
                </div>
              </div>
              <div className="w-full p-3 sm:w-1/2 sm:p-6">
                <Image image={service.image} imageSharp={service.imageSharp}/>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Services
